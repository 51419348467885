import { Controller } from '@hotwired/stimulus';
import { showModal, hideModal } from '../../helpers/jquery_wrapper';
import { jsonPost } from '../../helpers/fetch_utils';

export default class extends Controller {
  static values = {
    togglePcpPath: String,
    followUpCustomizationId: Number,
    deleteCtaPath: String,
    getAvailableCtasPath: String,
    createCtaPath: String,
    updateCtaPath: String,
  };

  static targets = [
    'pcpHeaderYes', 'pcpHeaderAny', 'rightColumnCell', 'leftColumnCell', 'ctaPreview',
    'selectModalBody', 'selectModal', 'ctaSet', 'disablePcpModal', 'enablePcpModal', 'toggleSwitch',
    'previewAddUseButton', 'autosave', 'additionalModal',
  ];

  ctaSetTargetConnected(target) {
    this.updateCtaSetView(target);
  }

  // Show/hide elements based on how many CTAs are in a given set
  updateCtaSetView(ctaSet) {
    const previewContainers = ctaSet.querySelectorAll('.unlock-cta-preview');
    const numCtas = previewContainers.length;

    // Show setup instructions when no CTAs are in set
    if (numCtas === 0) {
      ctaSet.querySelector('.no-ctas').classList.remove('hide');
      return;
    }

    previewContainers.forEach((previewContainer, ind) => {
      const addCtaLink = previewContainer.querySelector('.add-cta-link');

      if (ind === 0 && numCtas === 1) {
        // Show "+ Add Cta" link when only one CTA is in the set
        addCtaLink.classList.remove('hide');
      } else if (ind === 0) {
        // Show divider between first CTA and second CTA
        addCtaLink.classList.add('hide');
      } else if (ind === 1) {
        // Hide divider for bottom CTA
        addCtaLink.classList.add('hide');
      }
    });
  }

  // Triggered when modal is opened to create new CTA use
  setPendingCtaAsNew(e) {
    this.pendingCtaIsNew = true;
  }

  // Triggered when modal is opened to update existing CTA use
  setCtaToBeUpdated(e) {
    this.pendingCtaIsNew = false;
    this.ctaToUpdate = e.target.closest('.unlock-cta-preview');
  }

  getCtaIdsInSet(ctaSet) {
    const ctasInSet = ctaSet.querySelectorAll('.unlock-cta-preview');

    if (ctasInSet.length > 0) {
      return Array.from(ctasInSet).map((cta) => cta.dataset.ctaUseId);
    }

    return [];
  }

  async populateSelectModal(e) {
    this.pendingCtaSet = e.target.closest('.call-to-action-set');

    const body = {
      current_cta_use_ids: this.getCtaIdsInSet(this.pendingCtaSet),
    };

    const html = await jsonPost(this.getAvailableCtasPathValue, body, true);
    this.selectModalBodyTarget.innerHTML = html;
    this.showTimedAutosave();
  }

  previewUse(e) {
    e.preventDefault();

    const modal = e.target.dataset.isPrimary ? this.selectModalTarget : this.additionalModalTarget;
    const ctaId = e.target.dataset.id;

    this.previewingPrimary = e.target.dataset.isPrimary;
    this.selectScrollPosition = modal.scrollTop;
    this.previewAddUseButtonTarget.dataset.id = ctaId;
    this.previewingCtaInUse = e.target.dataset.inUse;
    hideModal(modal);
    ctaPreview.showPreviewModal(ctaId, { forUseSelect: true });
  }

  backToAvailable(e) {
    e.preventDefault();
    const modal = e.target.closest('#cta_preview_modal');
    hideModal(modal);

    const selectModal = this.previewingPrimary ? this.selectModalTarget : this.additionalModalTarget;
    showModal(selectModal);
    selectModal.scrollTop = this.selectScrollPosition;
  }

  async createCta(ctaId) {
    const body = {
      cta_id: ctaId,
      follow_up_plan_id: this.pendingCtaSet.dataset.followUpPlanId,
      has_pcp: this.pendingCtaSet.dataset.hasPcp,
      sort_order: this.pendingCtaSet.querySelectorAll('.unlock-cta-preview').length + 1,
    };

    const { previewHtml, duplicatedCtasMap } = await jsonPost(this.createCtaPathValue, body);
    this.pendingCtaSet.querySelector('.no-ctas').classList.add('hide');
    this.remapCtas(duplicatedCtasMap);
    const ctaNode = new DOMParser().parseFromString(previewHtml, 'text/html').body.firstElementChild;
    this.pendingCtaSet.appendChild(ctaNode);
    // Update links and dividers given new CTA addition
    this.updateCtaSetView(this.pendingCtaSet);
    hideModal(this.selectModalTarget);
    this.showTimedAutosave();
  }

  async updateCta(ctaId) {
    const body = {
      cta_id: ctaId,
      cta_use_id: this.ctaToUpdate.dataset.ctaUseId,
    };

    const { previewHtml, duplicatedCtasMap } = await jsonPost(this.updateCtaPathValue, body);
    this.remapCtas(duplicatedCtasMap);
    this.ctaToUpdate.outerHTML = previewHtml;
    this.updateCtaSetView(this.pendingCtaSet);
    hideModal(this.selectModalTarget);
    this.showTimedAutosave();
  }

  addCtaFromPreview(e) {
    if (this.previewingPrimary) {
      const modal = e.target.closest('#cta_preview_modal');
      hideModal(modal);

      if (this.previewingCtaInUse === 'false') {
        this.addCta(e);
      }
    } else {
      this.backToAvailable(e);
      this.additionalModalTarget.querySelector(`input[value='${e.target.dataset.id}']`).checked = true;
    }
  }

  addCta(e) {
    e.preventDefault();
    const ctaId = e.target.dataset.id;

    if (this.pendingCtaIsNew) {
      this.createCta(ctaId);
    } else {
      this.updateCta(ctaId);
    }
  }

  stageCtaRemoval(e) {
    this.ctaToRemove = e.target.closest('.unlock-cta-preview');
  }

  async removeCta() {
    const body = {
      cta_use_id: this.ctaToRemove.dataset.ctaUseId,
    };

    const { duplicatedCtasMap } = await jsonPost(this.deleteCtaPathValue, body);
    const ctaSet = this.ctaToRemove.parentElement;
    this.ctaToRemove.remove();
    this.remapCtas(duplicatedCtasMap);
    this.updateCtaSetView(ctaSet);
    this.showTimedAutosave();
  }

  handleToggleSwitch(e) {
    const numCtas = this.ctaPreviewTargets.length;

    if (numCtas === 0) {
      this.togglePcp(e.target.checked);
      return;
    }

    // Revert toggle switch and display confirmation modal
    e.target.checked = !e.target.checked;

    if (e.target.checked) {
      showModal(this.disablePcpModalTarget);
    } else {
      showModal(this.enablePcpModalTarget);
    }
  }

  confirmPcpDisable() {
    this.toggleSwitchTarget.checked = false;
    this.togglePcp(false);
  }

  confirmPcpEnable() {
    this.toggleSwitchTarget.checked = true;
    this.togglePcp(true);
  }

  togglePcp(enablePcp) {
    const body = {
      id: this.followUpCustomizationIdValue,
      display_pcp: enablePcp,
    };

    jsonPost(this.togglePcpPathValue, body)
      .then(({ duplicatedCtasMap, rightColumnCtaMap }) => {
        this.remapCtas(duplicatedCtasMap);
        enablePcp ? this.displayTwoColumns(rightColumnCtaMap) : this.displayOneColumn();
        this.showTimedAutosave();
      });
  }

  // AdditionalCtasController triggered a change that caused the cta uses to be duplicated,
  // which means we need to update the ids of the primary ctas as well.
  handleNewCtaUseIds(e) {
    this.remapCtas(e.detail.ctaMap, true);
  }

  // All of the call_to_action_uses records are duplicated when a pending customization is created.
  // Whenever that happens, we need to update the id values on the frontend to point to the newly created uses.
  remapCtas(ctaMap, skipDispatch) {
    if (!ctaMap) return;

    if (!skipDispatch) {
      this.dispatch('newCtaUseIds', { detail: { ctaMap } });
    }

    this.ctaPreviewTargets.forEach((cta) => {
      cta.dataset.ctaUseId = ctaMap[cta.dataset.ctaUseId];
    });
  }

  displayTwoColumns(rightColumnCtaMap) {
    this.leftColumnCellTargets.forEach((leftCell, ind) => {
      // Grab header of top right cell
      let topRightHeader;

      if (ind === 0) {
        topRightHeader = this.rightColumnCellTargets[0].querySelector('.pcp-header');
      }

      // Duplicate left cell on right cell
      const rightCell = leftCell.nextSibling;
      rightCell.innerHTML = leftCell.innerHTML;

      if (ind === 0) {
        rightCell.querySelector('.pcp-header').replaceWith(topRightHeader);
      }

      // Update dataset properties to reflect change of PCP status
      leftCell.querySelector('.call-to-action-set').dataset.hasPcp = 'true';
      rightCell.querySelector('.call-to-action-set').dataset.hasPcp = 'false';

      // Update the CTA's id based on the right column map
      rightCell.querySelectorAll('.unlock-cta-preview').forEach((cta) => {
        const leftId = cta.dataset.ctaUseId;
        cta.dataset.ctaUseId = rightColumnCtaMap[leftId];
      });
    });

    this.pcpHeaderAnyTargets.forEach((t) => t.classList.add('hide'));
    this.pcpHeaderYesTarget.classList.remove('hide');
    this.rightColumnCellTargets.forEach((t) => t.classList.remove('hide'));
  }

  displayOneColumn() {
    this.pcpHeaderAnyTarget.classList.remove('hide');
    this.pcpHeaderYesTarget.classList.add('hide');
    this.rightColumnCellTargets.forEach((t) => t.classList.add('hide'));

    // Remove CTA previews from right column
    this.rightColumnCellTargets.forEach((rightCell) => {
      rightCell.querySelectorAll('.call-to-action-set').forEach((set) => set.remove());
    });

    // Update dataset properties to reflect change of PCP status
    this.leftColumnCellTargets.forEach((leftCell) => {
      leftCell.querySelector('.call-to-action-set').dataset.hasPcp = '';
    });
  }

  // Shows "Autosaved" message for 5 seconds
  showTimedAutosave() {
    this.autosaveTargets.forEach((t) => t.classList.remove('hidden'));
    setTimeout(() => {
      this.autosaveTargets.forEach((t) => t.classList.add('hidden'));
    }, 5000);
  }
}
