import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    data: Object,
    options: Object, // custom highchart options that will be used instead of the one defined in this controller
    width: Number,
  };

  static targets = ['container'];

  connect() {
    this.setupChart();
  }

  setupChart() {
    let options;

    if (this.hasOptionsValue) {
      options = this.optionsValue;
    } else {
      options = {
        chart: {
          type: 'bar',
          borderRadius: 4,
          borderColor: '#D1D5DB',
          borderWidth: 1,
        },
        title: {
          text: this.dataValue.title,
          align: 'left',
        },
        subtitle: {},
        xAxis: {
          min: 0,
          minTickInterval: 1,
          categories: this.dataValue.xAxis,
        },
        yAxis: {
          min: 0,
          minTickInterval: 1,
          labels: {
            overflow: 'justify',
          },
          title: {
            enabled: this.dataValue.yAxis.title.enabled,
          },
        },
        tooltip: {
          headerFormat: '<span style="font-size:12px; font-weight: bold;">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
        },
        credits: {
          enabled: false,
        },
        series: this.dataValue.seriesData,
      };
    }

    if (this.hasWidthValue) {
      options.chart.width = this.widthValue;
    }

    Highcharts.chart(this.containerTarget.id, options);
  }
}
