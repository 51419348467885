import { Controller } from '@hotwired/stimulus';
import DropzoneCreator from '../../dropzone/dropzone_creator';

export default class extends Controller {
  colorValidation = {};

  static values = {
    dropzoneImages: Object,
    backgroundImageCustomUrl: String,
    imageUploadPath: String,
    context: String,
    productLineKey: String,
  };

  static targets = [
    'applicationNameRadios',
    'submit',
  ];

  connect() {
    this.dzCreator = new DropzoneCreator('#healthaware_application_branding_form', { listenToForm: false });

    const dzOpts = {
      acceptedFiles: 'image/png,image/jpeg',
      dictInvalidFileType: 'Image must be of type PNG or JPEG.',
      url: this.imageUploadPathValue,
    };

    Object.entries(this.dropzoneImagesValue).forEach(([name, attributes]) => this.createImageDropzone(name, attributes.url, attributes.default_image, { ...dzOpts, ...attributes.opts, paramName: name }, attributes.hidden_field_name));

    this.triggerEvent('change', this.applicationNameRadiosTarget);
  }

  createImageDropzone(name, url, defaultImage, opts, hidden_field_name) {
    this.dzCreator.createInstance(`#${name}_dropzone`, `#customizations_${this.productLineKeyValue}_application_branding_form_${hidden_field_name}`, url, opts, defaultImage);
  }

  colorValidated({ detail: { isValid, fieldName } }) {
    this.colorValidation[fieldName] = isValid;
  }

  toggleNameField(e) {
    // The selected radio button value (true means using default)
    const radioValue = this.getCheckedRadioValue(e.currentTarget) === 'true';
    const nameInput = e.currentTarget.querySelector('input[type="text"]');

    if (radioValue) {
      nameInput.disabled = 'disabled';
      nameInput.value = nameInput.dataset.default_value;
    } else {
      nameInput.disabled = '';
    }

    this.triggerEvent('change', nameInput);
  }

  triggerEvent(eventName, element) {
    const event = new Event(eventName);
    element.dispatchEvent(event);
  }

  getCheckedRadioValue(element) {
    return [...element.querySelectorAll('input[type="radio"]')].find((i) => i.checked).value;
  }

  submit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.submitTargets.forEach((s) => {
      s.value = s.dataset.disableWith;
      s.disabled = true;
    });

    this.dzCreator.processDzs();
  }
}
