import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'headerRadio', 'headerLogo', 'headerTextHeadline', 'headerCustomTextFieldTarget', 'textHeadlineRadios', 'headerCustomTextField',
  ];

  textHeadlineRadiosTargetConnected(elem) {
    if (elem.checked) this.toggleTextHeadline(elem);
  }

  headerRadioTargetConnected(elem) {
    if (elem.checked) this.headerDisplay(elem);
  }

  headerDisplayHandler(e) {
    this.headerDisplay(e.target);
  }

  headerDisplay(elem) {
    if (elem.value === 'logo') {
      this.headerLogoTarget.classList.remove('hide');
      this.headerTextHeadlineTarget.classList.add('hide');
    } else if (elem.value === 'custom_text') {
      this.headerLogoTarget.classList.add('hide');
      this.headerTextHeadlineTarget.classList.remove('hide');
    }
  }

  toggleTextHeadlineHandler(e) {
    this.toggleTextHeadline(e.target);
  }

  toggleTextHeadline(elem) {
    const value = elem.value;
    const textFieldTarget = this[elem.dataset.textFieldTarget];

    if (value === 'true') {
      textFieldTarget.disabled = true;
      textFieldTarget.value = textFieldTarget.dataset.defaultHeadline;

      // reset character counter
      const event = new Event('change');
      textFieldTarget.dispatchEvent(event);
    } else {
      textFieldTarget.disabled = false;
    }
  }
}
