import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'clientDropdown',
    'integrationDropdown',
  ];

  connect() {
    this.updateIntegrationDropdown();
  }

  addOption(text, value) {
    const option = document.createElement("option");
    option.textContent = text;
    option.value = value;
    this.integrationDropdownTarget.appendChild(option);
  }

  updateIntegrationDropdown() {
    this.integrationDropdownTarget.innerHTML = '';
    const checkedOption = this.clientDropdownTarget.querySelector('option:checked');
    const integrationData = JSON.parse(checkedOption.dataset.integrationData);

    integrationData.forEach((data) => {
      this.addOption(data.text, data.value);
    });

    // Select first option
    this.integrationDropdownTarget.querySelector('option').selected = true;
  }
}
