import { Controller } from '@hotwired/stimulus';
import { tooltip } from '../helpers/jquery_wrapper';

export default class extends Controller {
  static values = {
    placement: { type: String, default: 'auto top' },
    otherOptions: Object,
  };

  connect() {
    tooltip(this.element, { placement: this.placementValue, html: true, ...this.otherOptionsValue });
  }
}
