import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggle'];

  async connect() {
    const { default: hljs } = await import('../highlight');

    this.shown = sessionStorage.getItem('show_code') === 'true';
    this.showOrHidePreCode(this.shown);
    this.boundHandleToggle = this.handleKeyup.bind(this);

    // highlight code blocks in pattern library
    $('pre.code').each((i, block) => {
      hljs.highlightElement(block);
    });

    document.addEventListener('keyup', this.boundHandleToggle);
  }

  disconnect() {
    super.disconnect();
    document.removeEventListener('keyup', this.boundHandleToggle);
  }

  handleToggle(e) {
    e.preventDefault();
    this.showOrHidePreCode(!this.shown);
  }

  handleKeyup(e) {
    if (e.keyCode === 220) {
      this.showOrHidePreCode(!this.shown);
    }
  }

  showOrHidePreCode(show) {
    if (!this.hasToggleTarget) return;

    if (show) {
      this.shown = true;
      sessionStorage.setItem('show_code', 'true');
      $('pre.code').show();
      $(this.toggleTarget).text('[hide code]');
    } else {
      this.shown = false;
      sessionStorage.setItem('show_code', 'false');
      $('pre.code').hide();
      $('pre.code.always-show').show();
      $(this.toggleTarget).text('[show code]');
    }
  }
}
