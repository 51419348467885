import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    isHealthaware: Boolean,
  };

  static targets = ['iframe', 'previewModal', 'previewViewLightbox', 'previewSubject', 'previewContent', 'viewControls'];

  showPreview(e) {
    const iframe = this.iframeTarget;
    iframe.setAttribute('src', e.params.url);

    if (this.isHealthawareValue || e.params.isHealthaware) {
      if (e.params.isHealthaware) this.isHealthawareValue = e.params.isHealthaware;
      this.setMobile();
      this.viewControlsTarget.classList.add('hide');
    } else {
      this.setLightbox();
      this.viewControlsTarget.classList.remove('hide');
    }

    this.previewSubjectTarget.classList.remove('hide');
    this.previewContentTarget.classList.add('hide');
    this.previewViewChange();
  }

  previewViewChange() {
    // Healthaware can only be mobile view
    if (this.isHealthawareValue) return;

    this.previewViewLightboxTarget.checked ? this.setLightbox() : this.setMobile();
  }

  setMobile() {
    const iframe = this.iframeTarget;
    iframe.style.width = '375px';
    iframe.style.height = '667px';
  }

  setLightbox() {
    const iframe = this.iframeTarget;
    iframe.style.width = '950px';
    iframe.style.height = '650px';
  }

  handleMessage(e) {
    // Healthaware can only be mobile view
    if (this.isHealthawareValue) return;

    const action = e.data;

    if (action && action.type === 'RESIZE_FOR_SIDEBAR' && this.previewViewLightboxTarget.checked) {
      this.iframeTarget.style.width = '1200px';
      this.previewModalTarget.classList.add('modal-dialog-with-sidebar');
      this.previewModalTarget.querySelector('.modal-dialog').classList.add('modal-dialog-with-sidebar');
    }

    if (action && action.type === 'START_PROFILER_LOADING' && this.previewViewLightboxTarget.checked) {
      this.iframeTarget.style.width = '950px';
      this.previewModalTarget.classList.remove('modal-dialog-with-sidebar');
      this.previewModalTarget.querySelector('.modal-dialog').classList.remove('modal-dialog-with-sidebar');
    }
  }

  handleLoad(e) {
    this.previewSubjectTarget.classList.add('hide');
    this.previewContentTarget.classList.remove('hide');
  }
}
