import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['backgroundRadios', 'backgroundColor', 'backgroundImageControls', 'backgroundImageRadios'];

  connect() {
    this.toggleBackgroundControls();
    if (this.backgroundRadiosTargets.find((t) => t.checked).value === 'image') {
      this.updateBackgroundImage();
    }
  }

  toggleBackgroundControls() {
    const value = this.backgroundRadiosTargets.find((t) => t.checked).value;
    if (value === 'custom_color') {
      this.backgroundColorTarget.classList.remove('hide');
      this.backgroundImageControlsTarget.classList.add('hide');
    } else {
      this.backgroundColorTarget.classList.add('hide');
      this.backgroundImageControlsTarget.classList.remove('hide');

      // select the first option if there is no default when changing back and forth between background types.
      const checkedRadio = this.backgroundImageRadiosTargets.find((t) => t.checked);

      if (!checkedRadio) {
        this.backgroundImageRadiosTarget.click();
      } else {
        // Click checked option so that image will update
        checkedRadio.click();
      }
    }

    // Trigger color field validation
    const event = new Event('input');
    this.backgroundColorTarget.dispatchEvent(event);
  }

  updateBackgroundImage() {
    const elems = this.backgroundImageControlsTarget.querySelectorAll('.stock-img-container, #upload');
    elems.forEach((elem) => elem.classList.add('hide'));
    const id = `#${this.backgroundImageRadiosTargets.find((t) => t.checked).value}`;
    this.backgroundImageControlsTarget.querySelector(id).classList.remove('hide');
  }
}
