import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
  };

  static targets = [
    'filterUpArrow',
    'filterDownArrow',
    'filterDrawer',
    'firstFilterRow',
  ];

  toggleFilterDrawer() {
    if (this.filterUpArrowTarget.classList.contains('hide')) {
      this.firstFilterRowTarget.classList.add('small-margin');
      this.filterDrawerTarget.classList.remove('hide');
      this.filterDownArrowTarget.classList.add('hide');
      this.filterUpArrowTarget.classList.remove('hide');
    } else {
      this.firstFilterRowTarget.classList.remove('small-margin');
      this.filterDrawerTarget.classList.add('hide');
      this.filterDownArrowTarget.classList.remove('hide');
      this.filterUpArrowTarget.classList.add('hide');
    }
  }
}
