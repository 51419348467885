import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['colorInputField', 'colorSample'];

  static values = {
    fieldName: String,
  };

  connect() {
    this.updateColorFor();
  }

  // This could be de-jquery'd a bit, but it works
  updateColorFor() {
    const colorInput = $(this.colorInputFieldTarget);
    const color = this.formatColor(colorInput.val());
    const valid = this.validateColor(color) || colorInput.is(':hidden');
    colorInput.val(color);

    if (valid) {
      colorInput.closest('.form-group').removeClass('has-error');
      colorInput.siblings('.help-block').addClass('hidden');
      this.updateColorPreview(color);
      this.dispatch('colorUpdated', { detail: { newColor: color, fieldName: this.fieldNameValue } });
    } else {
      colorInput.closest('.form-group').addClass('has-error');

      if (colorInput.siblings('.help-block').length === 0) {
        colorInput.closest('.form-group').append('<span class="help-block">Please match the requested format (Example: FF0000 is red)</span>');
      } else {
        colorInput.siblings('.help-block').removeClass('hidden');
      }
      this.updateColorPreview('cccccc');
      this.dispatch('colorUpdated', { detail: { newColor: 'cccccc', fieldName: this.fieldNameValue } });
    }


    this.dispatch('colorValidated', { detail: { isValid: valid, fieldName: this.fieldNameValue } });
  }

  validateColor(formattedVal) {
    return /^([A-Fa-f0-9]{6})$/i.test(formattedVal);
  }

  formatColor(val) {
    if (val[0] === '#') {
      return val.slice(1, val.length);
    } else {
      return val;
    }
  }

  updateColorPreview(color) {
    $(this.colorSampleTarget).css('background-color', `#${color}`);
  }
}
