export default class NestedModal {
  constructor(baseModal) {
    this.clickingModal = false;
  }

  handleModalButtonClick(e, baseModal) {
    if (this.clickingModal) {
      this.clickingModal = false;
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    this.clickingModal = true;
    this.displayNestedModal(e.currentTarget, baseModal);
  }

  displayNestedModal(link, baseModal) {
    link.click();
    let modal = document.getElementById(link.dataset.target.slice(1));
    this.adjustModalStack(baseModal, modal);
  }

  adjustModalStack(lowerModal, upperModal) {
    let lowerZ = parseInt(getComputedStyle(lowerModal)['z-index']);
    let backdrop = [...document.querySelectorAll('.modal-backdrop')].pop();
    backdrop.style.zIndex = lowerZ + 1;
    upperModal.style.zIndex = lowerZ + 2;
  }
}
