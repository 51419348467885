import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['url', 'typeContainer', 'extension', 'extensionLabel', 'leftFormContainer'];

  static values = {};

  /**
   *
   * Handlers
   *
   */

  handleTypeChange(event) {
    const checkedTypeValue = this.getTypeValue();

    if (checkedTypeValue === 'url') {
      this.hideExtensionContainer();
    } else if (checkedTypeValue == 'phone_number' && this.urlTarget.value.length > 3) {
      this.showExtensionContainer();
    }

    this.removeValidationMessages();
  }

  handleUrlLengthChange(event) {
    const checkedTypeValue = this.getTypeValue();

    if (checkedTypeValue === 'phone_number' && this.urlTarget.value.length > 3) {
      this.showExtensionContainer();
    } else {
      this.hideExtensionContainer();
      this.removeExtensionValidationMessages();
    }
  }

  /**
   *
   * Private helper methods
   *
   */

  getTypeValue() {
    const checkedTypeRadio = this.typeContainerTarget.querySelector('input[type="radio"]:checked');
    return checkedTypeRadio ? checkedTypeRadio.value : null;
  }

  removeValidationMessages() {
    this.removeUrlValidationMessages();
    this.removeExtensionValidationMessages();
    this.removeTypeValidationMessages();
  }

  removeUrlValidationMessages() {
    if (this.urlTarget) {
      this.urlTarget.parentNode.classList.remove('has-error');
      if (this.urlTarget.parentNode.querySelector('.help-block')) {
        this.urlTarget.parentNode.querySelector('.help-block').classList.add('hidden');
      }
    }
  }

  removeExtensionValidationMessages() {
    if (this.extensionTarget) {
      this.extensionTarget.parentNode.classList.remove('has-error');
      if (this.extensionTarget.parentNode.querySelector('.help-block')) {
        this.extensionTarget.parentNode.querySelector('.help-block').classList.add('hidden');
      }
    }
  }

  removeTypeValidationMessages() {
    if (this.typeContainerTarget) {
      this.typeContainerTarget.classList.remove('has-error');
      if (this.typeContainerTarget.parentNode.querySelector('.help-block')) {
        this.typeContainerTarget.parentNode.querySelector('.help-block').classList.add('hidden');
      }
    }
  }

  showExtensionContainer() {
    this.extensionTarget.classList.remove('hidden');
    this.extensionLabelTarget.classList.remove('hidden');
    this.leftFormContainerTarget.classList.remove('width-100');
    this.leftFormContainerTarget.classList.add('width-80');
  }

  hideExtensionContainer() {
    this.extensionTarget.classList.add('hidden');
    this.extensionLabelTarget.classList.add('hidden');
    this.leftFormContainerTarget.classList.remove('width-80');
    this.leftFormContainerTarget.classList.add('width-100');
  }
}
