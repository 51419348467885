import { Controller } from '@hotwired/stimulus';
import { jsonPost } from '../../helpers/fetch_utils';

export default class extends Controller {
  static values = {
    appListPath: String,
  };

  static targets = [
    'appSelect',
    'appKeyContainer',
  ];

  connect() {
    this.updateAppKey();
  }

  async updateAppList(e) {
    this.appKeyContainerTarget.classList.add('hide');
    const apps = await jsonPost(this.appListPathValue, { client_id: e.target.value });
    this.appSelectTarget.querySelector('option').selected = true;
    this.appSelectTarget.querySelectorAll('option:not(:first-child)').forEach((option) => option.remove());

    apps.forEach((app) => {
      const option = document.createElement('option');
      option.text = app.name;
      option.value = app.id;
      option.dataset.appKey = app.key;
      this.appSelectTarget.appendChild(option);
    });
  }

  updateAppKey() {
    const selectedOption = this.appSelectTarget.querySelector('option:checked');

    if (!selectedOption || !selectedOption.value) {
      this.appKeyContainerTarget.classList.add('hide');
      return;
    }

    const key = selectedOption.dataset.appKey;
    this.appKeyContainerTarget.querySelector('.live-link').innerHTML = key;
    this.appKeyContainerTarget.classList.remove('hide');
  }
}
