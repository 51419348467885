import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    overview: Object,
    skipReportVisits: Boolean,
  };

  static targets = ['container'];

  connect() {
    const seriesData = this.getSeriesData();
    this.setupChart(seriesData);
  }

  getSeriesData() {
    const overview = this.overviewValue;
    const skipReportVisits = this.skipReportVisitsValue;
    const sections = [
      {
        baseName: 'HRA Visits',
        color: '#44243B',
        number: overview.visited,
        percent: null,
      }, {
        baseName: 'Began HRA',
        color: '#5C4956',
        number: overview.started,
        percent: overview.started_percentage,
      }, {
        baseName: 'Lead Captured',
        color: '#81747E',
        number: overview.leads_captured,
        percent: overview.leads_captured_percentage,
      }, {
        baseName: 'Completed HRA',
        color: '#A6A0A5',
        number: overview.completed,
        percent: overview.completion_percentage,
      }, {
        baseName: 'Clicked on any CTA',
        color: '#BDBABC',
        number: overview.clicked_any_cta,
        percent: overview.clicked_any_cta_percentage,
      }];

    return sections
      .filter((section) => !(skipReportVisits && section.baseName === 'HRA Visits'))
      .map((section) => ({
        ...section,
        name: this.getSectionName(section),
        // Each of the 4 sections are the same height, so set y to 25(%)
        y: 25,
      }));
  }

  getSectionName(section) {
    let name = `${section.baseName} <br>${section.number}`;
    if (section.percent) name += ` (${section.percent}%)`;
    return name;
  }

  toolTipFormatter() {
    let label = `${this.point.baseName}: ${this.point.number}`;
    if (this.point.percent) label += ` (${this.point.percent}%)`;
    return label;
  }

  setupChart(seriesData) {
    Highcharts.chart(this.containerTarget.id, {
      chart: {
        type: 'funnel',
        height: '500px',
        borderRadius: 4,
        borderColor: '#D1D5DB',
        borderWidth: 1,
        events: {
          // Data labels have to be manually drawn to display them inside of the chart instead of to the side
          load() {
            const chart = this;
            Highcharts.each(chart.series[0].data, (p) => {
              p.dataLabel.attr({
                x: (chart.plotWidth - chart.plotLeft) / 2,
                y: p.plotY - 35,
                'text-anchor': 'middle',
              });
            });
          },
          redraw() {
            const chart = this;
            Highcharts.each(chart.series[0].data, (p) => {
              p.dataLabel.attr({
                x: (chart.plotWidth - chart.plotLeft) / 2,
                y: p.plotY - 35,
                'text-anchor': 'middle',
              });
            });
          },
        },
      },
      series: [{
        data: seriesData,
      }],
      plotOptions: {
        series: {
          borderWidth: '0',
          dataLabels: {
            enabled: true,
            connectorWidth: 0,
            distance: 0,
            format: '{point.name}',
            crop: false,
            style: {
              color: '#ffffff',
              textOutline: 'none',
              fontSize: '23px',
              fontFamily: 'Graphik, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
              fontWeight: 500,
            },
          },
          width: '675px',
          // Width/Height of bottom part of funnel (the rectangular part)
          neckWidth: '40%',
          neckHeight: '30%',
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      tooltip: {
        formatter: this.toolTipFormatter,
      },
    });
  }
}
