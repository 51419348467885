import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selectionsGroup', 'selection', 'newSelectionLink', 'deleteSelectionLink', 'validationField',
    'toBeDeleted', 'requiredRadio', 'ctaAlert'];

  static values = {
    productLineInitials: String,
    addContentPath: String,
    questionType: String,
    token: String,
    disableInputs: Boolean,
  };

  connect() {
    if (this.questionTypeValue === 'radio_group' || this.questionTypeValue === 'checkbox_group') {
      this.updateAddSelectionLink();
      this.updateDeleteSelectionLinks();
    }

    if (this.disableInputsValue) this.disableInputs();
  }

  disableInputs() {
    // Set all form inputs to read only (must be readOnly instead of disabled for preview submission to work)
    const formControlInputs = this.element.querySelectorAll('input.form-control');
    formControlInputs.forEach((elem) => elem.readOnly = true);

    // Disable unchecked radio, leave checked radio for preview submission
    this.requiredRadioTargets.find((t) => !t.checked).disabled = true;

    this.newSelectionLinkTarget.classList.add('hide');
    this.deleteSelectionLinkTargets.forEach((t) => t.classList.add('hide'));
    this.ctaAlertTarget.classList.remove('hide');
  }

  updateAddSelectionLink() {
    let sizeLimit;

    if (this.productLineInitialsValue == 'mh') {
      sizeLimit = this.questionTypeValue === 'radio_group' ? 7 : 6;
    } else {
      sizeLimit = this.questionTypeValue === 'radio_group' ? 10 : 9;
    }

    if (this.selectionTargets.length >= sizeLimit) {
      this.newSelectionLinkTarget.classList.add('hide');
    } else {
      this.newSelectionLinkTarget.classList.remove('hide');
    }
  }

  updateDeleteSelectionLinks() {
    const mandatorySelections = this.questionTypeValue === 'radio_group' ? 2 : 1;
    const targets = this.deleteSelectionLinkTargets.slice(0, mandatorySelections);
    targets.forEach((t) => t.classList.add('hide'));
  }

  addSelection(e) {
    e.preventDefault();
    const url = `${this.addContentPathValue}?content_type=selection&token=${this.tokenValue}`;

    fetch(url)
      .then((response) => { if (response.ok) return response.text(); })
      .then((html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        const selection = div.querySelector('.selection');
        this.selectionsGroupTarget.append(selection);
        MaxCount.init();
        this.updateAddSelectionLink();
        this.updateSelectionSortOrders();
      });
  }

  updateSelectionSortOrders() {
    const selections = this.selectionTargets.filter((t) => !t.classList.contains('to-be-deleted'));
    selections.forEach((selection, i) => selection.querySelector('.sort-order').value = i);
  }

  deleteSelection(e) {
    e.preventDefault();
    const selection = e.target.closest('.selection');

    // Medicom and Healthaware versions of the page handle selections a bit differently due to the differences in the backend
    if (this.productLineInitialsValue == 'mh') {
      selection.remove();
    } else {
      selection.querySelector('.to-be-deleted-field').value = 'true';
      selection.classList.add('hide', 'to-be-deleted');
    }

    this.updateAddSelectionLink();
    this.updateSelectionSortOrders();
  }

  toggleRequiredValidation({ target }) {
    if (target.value == 'true') {
      this.validationFieldTarget.classList.remove('hide');
    } else {
      this.validationFieldTarget.classList.add('hide');
    }
  }

  removeQuestion(e) {
    this.toBeDeletedTarget.value = true;
  }
}
