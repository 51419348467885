import { Controller } from '@hotwired/stimulus';
import { alert } from '../../helpers/jquery_wrapper';
import { jsonPost } from '../../helpers/fetch_utils';

export default class extends Controller {
  static values = {
    reportPath: String,
    dismissNotificationPath: String,
    isCorporationDashboard: Boolean,
  };

  static targets = [
    'timePeriod',
    'active',
    'unlaunched',
    'suspended',
    'offline',
    'quickFind',
    'dashboardRow',
    'notificationAlert',
    'filterUpArrow',
    'filterDownArrow',
    'filterDrawer',
    'firstFilterRow',
  ];

  getFilters() {
    return {
      active: this.activeTarget.checked,
      unlaunched: this.unlaunchedTarget.checked,
      suspended: this.suspendedTarget.checked,
      offline: this.offlineTarget.checked,
      quickFind: this.quickFindTarget.value.toLowerCase().trim(),
    };
  }

  hraMeetsFilterCriteria(hra, filters) {
    const tagValue = hra.querySelector('.tag').innerText.toLowerCase().trim();
    const filterText = hra.dataset.filterText.toLowerCase().trim();
    const statuses = ['active', 'unlaunched', 'suspended', 'offline'];
    const checkedStatuses = statuses.filter((status) => filters[status]);

    if ((checkedStatuses.length === 0 || checkedStatuses.includes(tagValue)) && filterText.includes(filters.quickFind)) {
      return true;
    }

    return false;
  }

  runQuickFilter() {
    const filters = this.getFilters();

    this.dashboardRowTargets.forEach((hra) => {
      if (this.hraMeetsFilterCriteria(hra, filters)) {
        if (hra.dataset.hasLegacyApp === 'true') {
          // Update drawer state depending on whether legacy app also meets filter criteria
          const dropShadow = hra.nextElementSibling;
          const legacyApp = dropShadow.nextElementSibling;
          if (this.hraMeetsFilterCriteria(legacyApp, filters)) {
            hra.querySelector('.up-arrow').classList.remove('hide');
            hra.querySelector('.down-arrow').classList.add('hide');
            hra.classList.add('no-margin');
            dropShadow.classList.remove('hide');
          } else {
            hra.querySelector('.up-arrow').classList.add('hide');
            hra.querySelector('.down-arrow').classList.remove('hide');
            hra.classList.remove('no-margin');
            dropShadow.classList.add('hide');
          }
        }

        hra.classList.remove('hide');
      } else {
        hra.classList.add('hide');
      }
    });
  }

  async generateReport() {
    const data = {
      criteria: {
        time_period: this.timePeriodTarget.value,
        is_ajax: true,
      },
    };

    const json = await jsonPost(this.reportPathValue, data);
    this.setReportData(json);
  }

  setReportData(json) {
    json.rows.forEach((row) => {
      const template = document.createElement('template');
      template.innerHTML = row.content;
      this.dashboardRowTargets.filter((t) => t.dataset.rowId).find((t) => t.dataset.rowId.toString() === row.id.toString()).replaceWith(template.content.firstChild);
    });

    if (!this.isCorporationDashboardValue) {
      this.runQuickFilter();
    }
  }

  async dismissNotification(e) {
    const body = { user_notification_id: this.notificationAlertTarget.dataset.id };
    await jsonPost(this.dismissNotificationPathValue, body);
    alert(this.notificationAlertTarget, 'close');
  }

  sendReportToReporting(e) {
    e.preventDefault();
    const { url, authenticityToken } = e.target.dataset;
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', url);
    const criteria = ['startDate', 'endDate', 'applicationTypeId', 'applicationId'];

    criteria.forEach((criteriaStr) => {
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      // Filter parameters need to be in snake case
      hiddenField.setAttribute('name', `criteria[${criteriaStr.replace(/[A-Z]/g, (c) => `_${c.toLowerCase()}`)}]`);
      hiddenField.setAttribute('value', e.target.dataset[criteriaStr]);
      form.appendChild(hiddenField);
    });

    const authenticityTokenField = document.createElement('input');
    authenticityTokenField.setAttribute('type', 'hidden');
    authenticityTokenField.setAttribute('name', 'authenticity_token');
    authenticityTokenField.setAttribute('value', authenticityToken);
    form.appendChild(authenticityTokenField);

    document.body.appendChild(form);
    form.submit();
  }

  toggleLegacyAppDrawer(e) {
    const upgradedApp = e.target.closest('.module');
    const dropShadow = upgradedApp.nextElementSibling;
    const legacyApp = dropShadow.nextElementSibling;

    if (legacyApp.classList.contains('hide')) {
      legacyApp.classList.remove('hide');
      upgradedApp.querySelector('.down-arrow').classList.add('hide');
      upgradedApp.querySelector('.up-arrow').classList.remove('hide');
      upgradedApp.classList.add('no-margin');
      dropShadow.classList.remove('hide');
    } else {
      legacyApp.classList.add('hide');
      upgradedApp.querySelector('.down-arrow').classList.remove('hide');
      upgradedApp.querySelector('.up-arrow').classList.add('hide');
      upgradedApp.classList.remove('no-margin');
      dropShadow.classList.add('hide');
    }
  }

  toggleFilterDrawer() {
    if (this.filterUpArrowTarget.classList.contains('hide')) {
      this.firstFilterRowTarget.classList.add('small-margin');
      this.filterDrawerTarget.classList.remove('hide');
      this.filterDownArrowTarget.classList.add('hide');
      this.filterUpArrowTarget.classList.remove('hide');
    } else {
      this.firstFilterRowTarget.classList.remove('small-margin');
      this.filterDrawerTarget.classList.add('hide');
      this.filterDownArrowTarget.classList.remove('hide');
      this.filterUpArrowTarget.classList.add('hide');
    }
  }
}
