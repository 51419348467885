import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'toggle',
    'menu',
  ];

  static values = {
    dropdownPlacement: { type: String, default: 'auto' },
  };

  connect() {
    this.popperInstance = Popper.createPopper(this.toggleTarget, this.menuTarget, {
      placement: this.dropdownPlacementValue,
      strategy: 'fixed',
      disablePortal: true,
    });
    this.element.addEventListener('updatePopper', this.updatePopper.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('updatePopper', this.updatePopper.bind(this));
    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
  }

  async updatePopper() {
    if (this.popperInstance) {
      await this.popperInstance.update();
    }
  }
}
