import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['includeReportEmailRadio', 'withEmail', 'withoutEmail', 'reportDropdown', 'sharingDropdown'];

  connect() {
    this.togglePhotos();
  }

  togglePhotos() {
    if (this.includeReportEmailRadioTarget.checked) {
      this.withEmailTarget.classList.remove('hidden');
      this.withoutEmailTarget.classList.add('hidden');
    } else {
      this.withEmailTarget.classList.add('hidden');
      this.withoutEmailTarget.classList.remove('hidden');
    }
  }

  reportEmailPreviewClick(e) {
    e.preventDefault();
    this.showPreview(this.reportDropdownTarget.value);
  }

  sharingEmailPreviewClick(e) {
    e.preventDefault();
    this.showPreview(this.sharingDropdownTarget.value);
  }

  showPreview(val) {
    window.previews.showPreviewForEmail(val);
  }
}
