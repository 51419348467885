// Include any functions that use legacy jQuery here, so that when we phase out jQuery
// we can update these wrappers in this one spot instead of all over the code base

export function showModal(selector) {
  return $(selector).modal('show')
}

export function hideModal(selector) {
  return $(selector).modal('hide')
}

export function alert(selector, ...args) {
  return $(selector).alert(...args)
}

export function tooltip(selector, ...args) {
  return $(selector).tooltip(...args);
}

export function datepicker(selector, ...args) {
  return $(selector).datepicker(...args);
}
