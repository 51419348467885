import DropzoneInstance from './dropzone_instance'

export default class DropzoneCreator {
  constructor(formSelector, opts) {
    const that = this;
    opts = opts || {
      listenToForm: true
    }

    this.formSelector = formSelector
    this.instances = []
    this.numFinished = 0

    this.$form = $(formSelector)

    if (opts.listenToForm) {
      this.$form.on('submit',function(e) {
        e.preventDefault();
        that.processDzs()
      });
    }
  }

  createInstance(selector, formParamSelector, imageUrl, dzOpts, defaultImage) {
    const instance = new DropzoneInstance()
    instance.create(selector, formParamSelector, imageUrl, dzOpts, this.instanceFinished.bind(this), defaultImage)
    this.instances.push(instance)
  }

  processDzs() {
    var that = this
    _.each(this.instances, function(instance){
      if (instance.dzInstance.getQueuedFiles().length > 0) {
        instance.processQueue()
      } else {
        that.instanceFinished()
      }
    })
  }

  instanceFinished() {
    this.numFinished++
    if ( this.numFinished === this.instances.length ) {
      this.submitForm()
    }
  }

  submitForm() {
    this.$form.off('submit')
    this.$form.submit()
  }
}