import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // Disables most functionality across Customization and Follow Up Customization pages
  connect() {
    this.disableFormSubmits();
    this.disableInputs();
    this.hideInteractiveElements();
  }

  disableFormSubmits() {
    const buttons = document.querySelectorAll('.actions .btn-primary');

    buttons.forEach((action) => {
      // We can leave the navigation buttons visible
      if (action.innerText !== 'Return to Plan') {
        action.classList.add('hide');
      }
    });
  }

  disableInputs() {
    document.querySelectorAll('input, textarea, select').forEach((input) => input.disabled = true);
  }

  hideInteractiveElements() {
    const selectors = [
      'a.change', 'a.edit-notification', 'a.delete-notification', '#add_notification',
      '.vendor-links a', '#open-purchase-modal-button', '.create-new-links a', '.sort-down', '.sort-up',
      '#delete_ga', '#delete_tealium', '#delete_gtm', '#delete_ga_4',
    ];
    const interactiveElements = document.querySelectorAll(selectors.join(', '));
    interactiveElements.forEach((link) => link.classList.add('hide'));
  }

  // Disable any CKEditors once they are initialized
  disableCkEditor({ detail: { content } }) {
    CKEDITOR.instances[content.id].setReadOnly(true);
  }
}
