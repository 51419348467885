import { Controller } from '@hotwired/stimulus';
import {datepicker} from "../helpers/jquery_wrapper";

export default class extends Controller {
  static values = {
    startDate: String,
    endDate: String,
  };

  static targets = [
    'startDate',
    'endDate',
  ];

  connect() {
    this.setDatePicker();
  }

  setDatePicker() {
    datepicker('[data-behaviour~=datepicker]', {
      autoclose: true,
    });

    this.startDateTarget.readOnly = true;
    this.endDateTarget.readOnly = true;

    datepicker('#start_date_outer', 'update', this.startDateValue);
    datepicker('#end_date_outer', 'update', this.endDateValue);

    this.startDateTarget.value = this.formatDate(new Date(this.startDateValue));
    this.endDateTarget.value = this.formatDate(new Date(this.endDateValue));
  }

  formatDate(date) {
    return (("0" + (date.getMonth() + 1)).slice(-2)) + '/' + ("0" + date.getDate()).slice(-2) + '/' + date.getFullYear();
  }
}