import { Controller } from '@hotwired/stimulus';
import DropzoneCreator from '../../dropzone/dropzone_creator';

export default class extends Controller {
  static values = {
    riskFactors: Array,
    appType: String,
    imageUploadPath: String,
    dropzoneImages: Object,
    signatureColor: String,
  };

  static targets = [
    'primaryInterfaceColor',
    'primaryInterfaceColorBgClass',
    'headerFields',
    'defaultRadio',
    'headerColor',
    'showHeaderRadios',
    'submitButton',
  ];

  connect() {
    this.defaultRadioTargets.forEach((radio) => this.triggerEvent('change', radio));

    this.initHeaderFields();

    this.dzCreator = new DropzoneCreator('#routing_page_branding_form', { listenToForm: false });

    const dzOpts = {
      acceptedFiles: 'image/png,image/jpeg',
      dictInvalidFileType: 'Image must be of type PNG or JPEG.',
      url: this.imageUploadPathValue,
    };

    Object.entries(this.dropzoneImagesValue).forEach(([name, attributes]) => this.createImageDropzone(name, attributes.url, attributes.default_image, { ...dzOpts, ...attributes.opts, paramName: name }, attributes.hidden_field_name));
  }

  submit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.submitButtonTargets.forEach((s) => {
      s.value = s.dataset.disableWith;
      s.disabled = true;
    });

    this.dzCreator.processDzs();
  }

  createImageDropzone(name, url, defaultImage, opts, hidden_field_name) {
    this.dzCreator.createInstance(`#${name}_dropzone`, `#customizations_routing_page_branding_form_${hidden_field_name}`, url, opts, defaultImage);
  }

  triggerEvent(eventName, element) {
    const event = new Event(eventName);
    element.dispatchEvent(event);
  }

  updatePreview(e) {
    const color = `#${this.primaryInterfaceColorTarget.querySelector('input').value}`;
    this.primaryInterfaceColorBgClassTargets.forEach((elm) => elm.style.backgroundColor = color);
  }

  getCheckedRadioValue(element) {
    return [...element.querySelectorAll('input[type="radio"]')].find((i) => i.checked).value;
  }

  toggleDefaultCustomField(e) {
    // The selected radio button value (true means using default)
    const useDefault = this.getCheckedRadioValue(e.currentTarget) === 'true';

    if (useDefault) {
      e.currentTarget.nextSibling.classList.add('hide');
    } else {
      e.currentTarget.nextSibling.classList.remove('hide');
    }

    // Trigger color field validation
    this.triggerEvent('input', e.currentTarget.nextSibling);
  }

  initHeaderFields() {
    if (this.getCheckedRadioValue(this.showHeaderRadiosTarget) === 'true') {
      this.showHeaderFields();
    } else {
      this.hideHeaderFields();
    }
  }

  showHeaderFields() {
    this.headerFieldsTarget.classList.remove('hide');
  }

  hideHeaderFields() {
    this.headerFieldsTarget.classList.add('hide');
  }

  setCustomHeaderColor() {
    const input = this.headerColorTarget.querySelector('input');

    if (input.value === '') {
      input.value = this.signatureColorValue;
    }
  }
}
