import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['rescreeningFormGroup', 'hideScreeningRadio', 'hideRescreeningRadio', 'showRescreeningRadio',
    'exampleText', 'screeningBadge', 'rescreeningBadge'];

  connect() {
    if (this.hideScreeningRadioTarget.checked) this.hideScreeningRecommendations();
    if (this.hideRescreeningRadioTarget.checked) this.hideBottomBadge();
  }

  hideScreeningRecommendations() {
    this.rescreeningFormGroupTarget.classList.add('hide');
    this.hideRescreeningRadioTarget.checked = true;
    this.exampleTextTarget.classList.add('hide');
    this.screeningBadgeTarget.classList.add('hide');
    this.rescreeningBadgeTarget.classList.add('hide');
  }

  showScreeningRecommendations() {
    this.showRescreeningRadioTarget.checked = true;
    this.rescreeningFormGroupTarget.classList.remove('hide');
    this.exampleTextTarget.classList.remove('hide');
    this.screeningBadgeTarget.classList.remove('hide');
    this.rescreeningBadgeTarget.classList.remove('hide');
  }

  showBottomBadge() {
    this.rescreeningBadgeTarget.classList.remove('hide');
  }

  hideBottomBadge() {
    this.rescreeningBadgeTarget.classList.add('hide');
  }
}
