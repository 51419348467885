import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['useTimeoutInput', 'timeoutLengthRow', 'timeoutLengthInput'];

  connect() {
    this.toggleTimeout();
  }

  toggleTimeout() {
    if (this.useTimeoutInputTarget.checked) {
      this.timeoutLengthInputTarget.removeAttribute('disabled');
      this.timeoutLengthRowTarget.classList.remove('hidden');
    } else {
      this.timeoutLengthInputTarget.setAttribute('disabled', true);
      this.timeoutLengthRowTarget.classList.add('hidden');
    }
  }
}
