import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    appDataUrl: String,
    isV3: Boolean,
    routingPageId: String,
  };

  static targets = [
    'clientId', 'brandId', 'locale', 'applicationTypeId', 'productLineId', 'v3', 'v2',
    'primaryContactId', 'newBrand', 'newPrimaryContact', 'subAppType', 'routingPageAppType',
  ];

  static classes = ['hide'];

  connect() {
    if (this.hasV3Target) {
      this.toggleV3(this.isV3Value);
    }

    if (this.hasBrandIdTarget) {
      this.brandChanged();
    }

    this.subAppTypeChanged();
    this.routingPageChanged();
    this.contactChanged();
  }

  async appTypeChanged() {
    const url = new URL(this.appDataUrlValue);
    const params = {
      client_id: this.clientIdTarget.value,
      application_type_id: this.applicationTypeIdTarget.value,
      product_line_id: this.productLineIdTarget.value,
    };

    url.search = new URLSearchParams(params).toString();
    const response = await fetch(url);
    const data = await response.json();
    this.changeSuccess(data);
  }

  changeSuccess(data) {
    this.populateSelect(this.subAppTypeTarget, data.sub_app_types);
    this.populateSelect(this.localeTarget, data.locales);
    this.populateSelect(this.primaryContactIdTarget, data.primary_contacts);
    this.subAppTypeChanged();
    this.routingPageChanged();
    this.contactChanged();

    if (this.hasV3Target) {
      this.toggleV3(data.is_v3);
    }

    if (this.hasBrandIdTarget) {
      this.populateSelect(this.brandIdTarget, data.brands);
      this.brandChanged();
    }
  }

  toggleV3(is_v3) {
    if (is_v3) {
      this.v2Target.classList.add(this.hideClass);
      this.v3Target.classList.remove(this.hideClass);
    } else {
      this.v2Target.classList.remove(this.hideClass);
      this.v3Target.classList.add(this.hideClass);
    }
  }

  subAppTypeChanged() {
    if (this.subAppTypeTarget.value !== '') {
      this.subAppTypeTarget.closest('.form-group').classList.remove(this.hideClass);
    } else {
      this.subAppTypeTarget.closest('.form-group').classList.add(this.hideClass);
    }
  }

  routingPageChanged() {
    if (this.applicationTypeIdTarget.value === this.routingPageIdValue) {
      this.routingPageAppTypeTarget.closest('.form-group').classList.remove(this.hideClass);
    } else {
      this.routingPageAppTypeTarget.closest('.form-group').classList.add(this.hideClass);
    }
  }

  contactChanged() {
    if (this.primaryContactIdTarget.value === 'new') {
      this.newPrimaryContactTarget.classList.remove(this.hideClass);
    } else {
      this.newPrimaryContactTarget.classList.add(this.hideClass);
    }
  }

  brandChanged() {
    if (this.brandIdTarget.value === 'new') {
      this.newBrandTarget.classList.remove(this.hideClass);
    } else {
      this.newBrandTarget.classList.add(this.hideClass);
    }
  }

  populateSelect(select, options) {
    // clear out existing options
    select.innerHTML = '';

    // add each of the options
    options.forEach((value) => {
      const option = document.createElement('option');
      option.value = value[1];
      option.text = value[0];
      select.appendChild(option);
    });
  }
}
